@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Hairline.eot');
    src: url('fonts/Lato-Hairline.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-Hairline.woff2') format('woff2'),
         url('fonts/Lato-Hairline.woff') format('woff'),
         url('fonts/Lato-Hairline.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-HairlineItalic.eot');
    src: url('fonts/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-HairlineItalic.woff2') format('woff2'),
         url('fonts/Lato-HairlineItalic.woff') format('woff'),
         url('fonts/Lato-HairlineItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Thin.eot');
    src: url('fonts/Lato-Thin.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-Thin.woff2') format('woff2'),
         url('fonts/Lato-Thin.woff') format('woff'),
         url('fonts/Lato-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 200;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-ThinItalic.eot');
    src: url('fonts/Lato-ThinItalic.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-ThinItalic.woff2') format('woff2'),
         url('fonts/Lato-ThinItalic.woff') format('woff'),
         url('fonts/Lato-ThinItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 200;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Light.eot');
    src: url('fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-Light.woff2') format('woff2'),
         url('fonts/Lato-Light.woff') format('woff'),
         url('fonts/Lato-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-LightItalic.eot');
    src: url('fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-LightItalic.woff2') format('woff2'),
         url('fonts/Lato-LightItalic.woff') format('woff'),
         url('fonts/Lato-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Regular.eot');
    src: url('fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-Regular.woff2') format('woff2'),
         url('fonts/Lato-Regular.woff') format('woff'),
         url('fonts/Lato-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Italic.eot');
    src: url('fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-Italic.woff2') format('woff2'),
         url('fonts/Lato-Italic.woff') format('woff'),
         url('fonts/Lato-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Medium.eot');
    src: url('fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-Medium.woff2') format('woff2'),
         url('fonts/Lato-Medium.woff') format('woff'),
         url('fonts/Lato-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-MediumItalic.eot');
    src: url('fonts/Lato-MediumItalic.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-MediumItalic.woff2') format('woff2'),
         url('fonts/Lato-MediumItalic.woff') format('woff'),
         url('fonts/Lato-MediumItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Semibold.eot');
    src: url('fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-Semibold.woff2') format('woff2'),
         url('fonts/Lato-Semibold.woff') format('woff'),
         url('fonts/Lato-Semibold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-SemiboldItalic.eot');
    src: url('fonts/Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-SemiboldItalic.woff2') format('woff2'),
         url('fonts/Lato-SemiboldItalic.woff') format('woff'),
         url('fonts/Lato-SemiboldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Bold.eot');
    src: url('fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-Bold.woff2') format('woff2'),
         url('fonts/Lato-Bold.woff') format('woff'),
         url('fonts/Lato-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-BoldItalic.eot');
    src: url('fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-BoldItalic.woff2') format('woff2'),
         url('fonts/Lato-BoldItalic.woff') format('woff'),
         url('fonts/Lato-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Heavy.eot');
    src: url('fonts/Lato-Heavy.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-Heavy.woff2') format('woff2'),
         url('fonts/Lato-Heavy.woff') format('woff'),
         url('fonts/Lato-Heavy.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-HeavyItalic.eot');
    src: url('fonts/Lato-HeavyItalic.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-HeavyItalic.woff2') format('woff2'),
         url('fonts/Lato-HeavyItalic.woff') format('woff'),
         url('fonts/Lato-HeavyItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 800;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-Black.eot');
    src: url('fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-Black.woff2') format('woff2'),
         url('fonts/Lato-Black.woff') format('woff'),
         url('fonts/Lato-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Lato;
    src: url('fonts/Lato-BlackItalic.eot');
    src: url('fonts/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
         url('fonts/Lato-BlackItalic.woff2') format('woff2'),
         url('fonts/Lato-BlackItalic.woff') format('woff'),
         url('fonts/Lato-BlackItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
    text-rendering: optimizeLegibility;
}
